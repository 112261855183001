import { gql, useQuery } from "@apollo/client";
import makeFormattedPastDateFilter from "modules/common/utils/makeFormattedPastDateFilter";
import { useIntl } from "react-intl";
import useEventsCategories from "./useEventsCategories";
import normalizeDateFormat from "modules/common/utils/normalizeCockpitDateField";

export const assignHumanReadableTargetGroup =
  (categories) => (prev, current) => {
    const targetGroup = (categories || [])?.find(
      ({ _id }) => _id === current?.targetGroup?._id,
    )?.navigationTitle;
    return [
      ...prev,
      {
        ...current,
        targetGroup,
      },
    ];
  };

const filterByCategory = (categoryId) => (e) => {
  if (categoryId) {
    return e.targetGroup._id === categoryId;
  }
  return true;
};

type EventFilter = {
  language: string;
  targetGroup?: string;
};
const GET_EVENTS = gql`
  query cmsGetEvents($lang: String, $filter: JsonType, $limit: Int) {
    eventsTreeModel(
      locale: $lang
      filter: $filter
      sort: { _o: 1 }
      limit: $limit
    ) {
      _id
      title
      dateShort
      slug
      archiveDate
      location
      targetGroup
      locationShort
      image
      title_slug
    }
  }
`;

const GET_EVENT = gql`
  query cmsGetEvents($lang: String, $filter: JsonType) {
    eventsTreeModel(locale: $lang, filter: $filter, limit: 1) {
      _id
      title
      subTitle
      image
      content
      pdfSchedule
      location
      archiveDate
      date
      costs
      elog
      dateShort
      signup
      targetGroup
      title_slug
      slug
      link
      schedule {
        value {
          date
          time
          title
          description
        }
      }
      routeLink
      speakers {
        value {
          photo
          name
          description
        }
      }
      closed
      handouts
    }
  }
`;

const useCMSEvents = ({ limit = 0, categoryId = "" }) => {
  const intl = useIntl();
  const lang = intl.locale.split("-")[0];
  const { categories } = useEventsCategories();

  const filter: EventFilter = {
    language: lang,
  };

  const { data, error, loading } = useQuery(GET_EVENTS, {
    variables: {
      filter,
    },
  });

  return {
    loading,
    error,
    events: (data?.eventsTreeModel || [])
      .map(normalizeDateFormat("archiveDate"))
      .filter(makeFormattedPastDateFilter("archiveDate"))
      .filter(filterByCategory(categoryId))
      .slice(0, limit || undefined)
      .reduce(assignHumanReadableTargetGroup(categories), []),
  };
};

export const preFetchEvents = async (
  apolloClient,
  { locale, limit }: { locale: string; limit?: number },
) => {
  const filter: any = {
    language: locale,
  };

  const { data } = await apolloClient.query({
    query: GET_EVENTS,
    variables: {
      filter,
      limit,
    },
  });

  return data?.eventsTreeModel || [];
};

export const preFetchEvent = async (
  apolloClient,
  { locale, slugAndId, categories },
) => {
  const filter: any = {
    language: locale,
  };

  if (slugAndId) {
    const id = [...slugAndId.split("-")].pop();

    filter.$or = [{ _id: id }, { title_slug: slugAndId }];
  }

  const { data } = await apolloClient.query({
    query: GET_EVENT,
    variables: {
      filter,
    },
  });

  return (data?.eventsTreeModel || [])
    .map(normalizeDateFormat("archiveDate"))
    .reduce(assignHumanReadableTargetGroup(categories), [])
    ?.pop();
};

export default useCMSEvents;
